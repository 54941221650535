<template>
    <div class="content">
        <div class="search-attr">
            <div style="display:flex">
                <a-select placeholder='选择群聊' v-model="currentId" @change="changeGroup" size='small' style="width:150px;"  :filter-option="untils.filterOption" showSearch >
                    <a-select-option v-for="item of groupList" :value='item.chatId' :key="item.id">{{item.chatName}}</a-select-option>
                </a-select>
                <a-popconfirm
                    title="确认删除本页数据么?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="removeMessage('all',data)"
                    @cancel="cancel"
                >
                    <a-button style="margin:0 10px;" size='small' >删除本页</a-button>
                </a-popconfirm>
                <!-- <a-button @click="removeMessage('all',data)" style="margin:0 10px;" size='small' >删除本页</a-button> -->
                <a-button size='small' @click="openModal">发布</a-button>
                <div style="display:flex;align-items:center;margin:0 10px;">
                    <div class="img-box" v-for="(item,index) of cell_urls" :key="index">
                        <img @click="previewImg(item)" style="max-width:30px;max-height:30px;" :src="item" alt="">
                        <a-icon @click="removeImg(cell_urls,index)" style="position:absolute;top:-5px;right:-5px;" type="close-circle" />
                    </div>
                </div>
                
            </div>
        </div>
        <div class="table-content" >
            <a-table :row-selection="rowSelection" :pagination='false'  :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
                <div slot="user" slot-scope="row,d,e">
                    <div >
                        <div @click="checkrowNames(row,d,e)">
                           {{row.senderUser.nickname}}
                        </div>
                        <div v-if="row.senderUser.receptReportNum||row.senderUser.receptBlockedNum>0">标记假货:<span style="color:red;">{{row.senderUser.receptReportNum}}</span>，被屏蔽:<span style="color:red;">{{row.senderUser.receptBlockedNum}}</span></div>
                        
                    </div>
                </div>
                <div slot="content" slot-scope="row">
                    <img @click="previewImg(row.filepath)" style="max-width:100px;max-height:100px" v-if="row.msgtype == 3" :src="row.filepath" alt="">
                    <div v-else>{{row.content}}</div>
                </div>
                <div slot="setup" slot-scope="row" style="display:flex;">
                    <a-button @click="setReport(row)" v-if="row.msgtype == 3" size='small' style="margin-right:5px"  type='danger'>标记假货</a-button>
                    <a-button @click="removeMessage(1,row.id)" style="margin-left:5px" size='small'>删除</a-button>
                    
                </div>
            </a-table>
        </div>
        <div class="footer">
            <a-button @click="downPage('next')" style="margin-right:10px;">上一页</a-button>
            <a-button @click="downPage('pre')" style="margin-left:10px">下一页</a-button>
            
        </div>
         <a-modal :keyboard='false' :width='800' v-model="visible" title='发布货品' @ok='hideModal' @cancel='resetForms'>
            <a-form-model ref="ruleForm"
                :model="forms"
                :rules="rules" v-bind="layout">
                <a-form-model-item label='发布者名称'>
                    {{forms.nickname}}
                </a-form-model-item>
                <a-form-model-item label='选择币种' prop='coinId'>
                    <a-select @search='searchData' ref='addse' v-model="forms.coinId" :filter-option="untils.filterOption" showSearch placeholder='请选择'>
                        <a-select-option
                            v-for="item of currency_big_list"
                            :key="item.id" :value='item.id'
                        >
                          {{item.coinKindName + (item.coinCategory ? `---${item.coinCategory}` : '' )}}
                          <span v-if="item.dynastyName"> {{item.dynastyName}}</span>
                          <span v-if="item.dynastyName"> {{item.rulerName}}</span>
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
               
                <a-form-model-item label='货品名称' >
                    <a-textarea v-model="forms.productShelvesName" auto-size />
                    <!-- <a-input v-model="forms.productShelvesName"></a-input> -->
                </a-form-model-item>
                <a-form-model-item label='货品价格'>
                    <a-input v-model="forms.price"></a-input>
                </a-form-model-item >
                <a-form-model-item label='货品备注'>
                    <a-input v-model="forms.productShelvesRemark"></a-input>
                </a-form-model-item>
                 <a-form-model-item label='商品图'>
                    <div style="display:flex">
                        <div style="margin:5px 10px;position:relative" v-for="(item,index) of forms.urls" :key="index">
                            <img @click="previewImgs(forms.urls,index)" style="max-width:50px;max-height:50px;" :src="item" alt="">
                            <a-icon @click="removeImg(forms.urls,index)" style="position:absolute;top:-5px;right:-5px;" type="close-circle" />
                        </div>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {getBigCoinList} from "@/axios/apis"

export default {
    data(){
        return {
            columns:[
                {title:'昵称', scopedSlots: { customRender: 'user' }},
                {title:'内容',scopedSlots: { customRender: 'content' },width:400},
                {title: '时间',dataIndex: 'createTime'},
                {title: 'id',dataIndex: 'id'},
                {title:'操作',align:'center',scopedSlots: { customRender: 'setup' },},
            ],
            data:[],
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
            visible:false,
            loading:false,
            messages:[],
            groupList:[],
            groupId:null,
            currentId:null,
            selectedRows:[],
            forms:{
                urls:[]
            },
            rules:{
                coinId:[{ required: true, message: 'please change', trigger: 'change' }],
                // productShelvesName:[{ required: true, message: 'please enter coinKindItemName', trigger: 'blur' }],
                // productShelvesRemark:[{ required: true, message: 'please enter', trigger: 'blur' }],
                // price:[{ required: true, message: 'please enter', trigger: 'blur' }],
            },
            currency_big_list:[],
            cell_urls:[],
            pageFirst:true,
            selectedRowKeys:[],
            userIds:[],
            rowidIndex:0,
            colors:[],
            nextCurrentMessaid:null,
            newxtCurrentType:'',
            sortIndexs:[]
        }
    },
    created(){
        this.getGroupList()
    },
    mounted(){
        addEventListener('paste',(e)=>{
            this.untils.pasteImg(e,1,this.paseImgs)
        })
    },
    computed: {
        rowSelection() {
            return {
                selectedRowKeys:this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys
                    this.selectedRows = selectedRows;
                },
            };
        },
    },
    methods:{
        async getGroupList(name=''){
           const result = await this.axios('/dq_admin/wxGroup/listAllGroup',{groupName:''})
           const {status,data} = result
           if(status == 200){
               this.currentId = data[0]['chatId']
               this.groupList = data;
               this.getGroupMessage(this.currentId);
           }

        },
        async changeGroup(e){
            this.groupId = e;
            await this.getGroupMessage(e);
        },
        async getGroupMessage(id,type=null,conditionId){
            const params = {groupChatId:id}
            if(type!=null){
                params.type = type;
                params.conditionId = conditionId
            }
            const result = await this.axios('/dq_admin/wxGroup/listGroupContent',{params:params})
            const {status,data,message} = result
            if(status == 200){
                if(data!=null&&data.length>0){
                    this.data = data
                    this.selectedRowKeys = [];
                    this.userIds = Array.from(new Set(this.data.map(row=>row.senderUser.id)));
                    this.colors = this.userIds.map(row=>this.color16())
                }else{
                    // this.data = [];
                    this.$message.success(message||'暂无数据')
                }
                
            }
        },
        previewImg(row){
            this.$previewImg({
                list:[{img_url:row}],
                baseImgField:'img_url',
                showMute:false,
            })
        },
        previewImgs(array,index){
            const result = array.map((row)=>{
                const params = {
                    img_url:row
                }
                return params
            })
            this.$previewImg({
                list:result,
                baseImgField:'img_url',
                showMute:false,
                index:index,
            })
        },
        async removeMessage(type=1,obj){//type=all
            let params = [];
            if(type == 'all'){
                params = obj.map(row=>row.id);
            }else{
                params.push(obj)
            }
            const result = await this.axios.post('/dq_admin/wxGroup/batchDel',{ids:params})
            const {status,message} = result
            if(status == 200){
                this.$message.success(message)
                this.getGroupMessage(this.currentId,this.newxtCurrentType,this.nextCurrentMessaid)
            }
            
        },
        openModal(){
            if(this.selectedRowKeys.length<1){
                return this.$message.error('请勾选至少一项')
            }
            const list = this.selectedRowKeys.map(row=>this.data[row]);
            const first = list[0]
            const ids = list.map(row=>row.id);
            const sendUserIds = list.map((row)=>row.senderUser.id)
            const is_bool = sendUserIds.every(item=>item==sendUserIds[0])
            const text_str = list.reduce((pre,cur)=>{
                if(cur.msgtype == 1){
                    pre.push(cur.content)
                }
                return pre;
            },[])
            if(text_str.length>0){
                this.forms.productShelvesName = text_str.join(',')
            }
            if(is_bool == false){
                return this.$message.warning('请选择相同的用户');
            }
            
            const urls = list.reduce((pre,cur)=>{
                if(cur.msgtype == 3){
                    pre.push(cur.filepath)
                }
                return pre
            },[])
            if(urls.length<1){
                return this.$message.warning('请重新选择')
            }
            this.visible = true;
            let params = {
                nickname:first.senderUser.nickname,
                wxUserInfoId:first.senderUser.id,
                urls:[...this.cell_urls,...urls],
                ids,
                chatroomid: first.chatroomid,
            }
            Object.assign(this.forms,params)
        },
        async searchData(inner){
          const res = await getBigCoinList(inner)
          if (res.status !== '200') return;
          const {records} = res.data
          this.currency_big_list = records
        },
        paseImgs(type,data,row){
            this.cell_urls.push(data)
        },
        removeImg(array,index){
            array.splice(index,1);
        },
        hideModal(){
            this.$refs.ruleForm.validate(valid => {
                if(valid){
                    this.submit(this.forms)
                }else{
                    return false;
                }
            });
        },
        async submit(forms){
            const result = await this.axios.post('/dq_admin/wxGroup/addToProductShelves',forms)
            const {status,message} = result;
            if(status == 200){
                this.$message.success(message)
                this.getGroupMessage(this.currentId,this.newxtCurrentType,this.nextCurrentMessaid)
                this.cell_urls = []
                this.forms = {urls:[]}
                this.selectedRows = [];
                this.selectedRowKeys = [];
                this.visible = false
                this.currency_big_list = [];
            }
        },
         resetForms(){
            this.forms = {urls:[]}
            this.selectedRows = []
            this.selectedRowKeys = [];
            this.$refs.ruleForm.resetFields();
        },
        downPage(type){
            const list = this.data.map((row)=>row.id);
            let id;
            if(type == 'pre'){
                id = Math.min(...list)
            }else{
                id = Math.max(...list)
            }
            this.nextCurrentMessaid = id;
            this.newxtCurrentType = type
            this.getGroupMessage(this.currentId,type,id);
            
        },
        // setRowClass(row,index){
        //     const rowidIndex = this.userIds.findIndex(d=>d == row.senderUser.id);
        //     return {
        //         style:{
        //             'background-color':this.colors[rowidIndex],
        //         }
        //     }
        // },
        color16(){//十六进制颜色随机
			var r = Math.floor(Math.random()*256);
			var g = Math.floor(Math.random()*256);
			var b = Math.floor(Math.random()*256);
			var color = '#'+r.toString(16)+g.toString(16)+b.toString(16);
			return color;
		},
        checkrowNames(row,index,d){
        },
        cancel(e){
        },
        async setReport(row){
            const result = await this.axios.get('/dq_admin/wxGroup/reportUser',{params:{wxUserInfoId:row.senderUser.id}})
            const {status,message} = result
            if(status == 200){
                this.$message.success(message);
                this.getGroupMessage(this.currentId,this.newxtCurrentType,this.nextCurrentMessaid);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.fixed-tip{top:-5px;right:-5px;padding:5px;background:red;color:white;position:absolute;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;}
.img-box{width:30px;height:30px;display:flex;justify-content: center;align-items: center;position: relative;margin:0 10px;}
.footer{display: flex;justify-content: center;align-items: center;}
</style>